<template>
	<div class="login">
		<header class="login__header">
			<HeaderVisuallyImpairedBlock
				v-if="visuallyImpaired"
				:class="[{ active: visuallyImpairedShow }]"
			></HeaderVisuallyImpairedBlock>
			<div class="container">
				<div class="login__header-box flex flex--between flex--middle">
					<a @click="$router.go(-1)" class="link link--back">
						Вернуться назад
					</a>

					<div class="login__header-nav flex flex--middle">
						<a href="tel:88000000000" class="link">
							<span class="icon icon--phone-white"></span>
							8 (800) 000 00 00
						</a>
						<button
							class="link link--mobile-hide"
							@click="switchdVisuallyImpaired"
						>
							<span class="icon icon--eye-white"></span>
							{{ visuallyImpairedText }}
						</button>
						<button
							v-if="visuallyImpaired"
							class="link"
							@click="visuallyImpairedShowToggle"
						>
							<span class="icon icon--eye"></span>
							Открыть панель настроек
						</button>
					</div>
				</div>
			</div>
		</header>
		<main class="login__main">
			<div class="container">
				<section class="login__box">
					<Logo class="login__logo" />
					<form @submit.prevent class="form">
						<label class="form__label">
							<span class="visually-hidden">
								Номер карты, телефон, СНИЛС
							</span>
							<input
								type="text"
								class="form__input"
								placeholder="Номер карты, телефон, СНИЛС"
							/>
						</label>
						<label class="form__label">
							<span class="visually-hidden">
								Пароль
							</span>
							<input type="text" class="form__input" placeholder="Пароль" />
						</label>

						<div class="flex flex--center">
							<button type="submit" class="button">
								Войти
							</button>
						</div>
					</form>
					<div class="flex flex--center">
						<a href="#" @click.prevent class="login__link link">
							Забыли пароль?
						</a>
					</div>
				</section>
			</div>
		</main>
	</div>
</template>

<script>
import HeaderVisuallyImpairedBlock from '@/components/HeaderVisuallyImpairedBlock.vue'

import Logo from '@/assets/images/logos/logo.svg'

export default {
	name: 'Login',

	components: {
		HeaderVisuallyImpairedBlock,
		Logo,
	},

	data() {
		return {
			visuallyImpaired: false,
			visuallyImpairedShow: false,
			visuallyImpairedText: 'Версия для слабовидящих',
		}
	},

	methods: {
		switchdVisuallyImpaired() {
			if (!this.visuallyImpaired) {
				this.visuallyImpaired = true
				this.visuallyImpairedText = 'Обычная версия'
				this.$store.dispatch('SET_VISUALLY_IMPAIRED', 1)
			} else {
				this.visuallyImpaired = false
				this.visuallyImpairedText = 'Версия для слабовидящих'
				this.$store.dispatch('SET_VISUALLY_IMPAIRED', 0)
			}
		},

		visuallyImpairedShowToggle() {
			if (this.visuallyImpairedShow) {
				this.visuallyImpairedShow = false
				this.$store.dispatch('SET_BLOCK_SCROLL', false)
			} else {
				this.visuallyImpairedShow = true
				this.$store.dispatch('SET_BLOCK_SCROLL', true)
			}
		},
	},

	mounted() {
		this.$root.$on('hideVisuallyImpaired', () => {
			this.visuallyImpairedShowToggle()
		})
	},
}
</script>
